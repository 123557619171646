import React from "react";
import { Link, graphql } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";
import Pageheader from "../components/pageheader";
import "../stylesheets/galleryGrid.scss";

const Photos = ({ data }) => {
  const photoItems = data.allContentfulPhotos.edges;

  const { heroImage, title, body } = data.contentfulPageHeader;

  return (

    <Layout>
      <SEO title="Photos" />
      <Pageheader title={title} image={heroImage} body={body}/>
      <div className="container">
        <div className="row">
          <div className="col">
            {photoItems.map(({ node: post }, index) => (
              <div key={post.id} className="galleryGrid">
                 <Link to={`/photos/${post.slug}`}>
                  <h2 className="text-center">
                    {post.title}
                  </h2>
                  {post.photos.slice(0, 1).map((item) => (
                    <img src={item.fixed.src} alt={item.title} className="img-fluid" />
                  ))}
                </Link>
              </div>
            ))}
          </div>
        </div>
      </div>
    </Layout>


  );
};
export default Photos;
export const query = graphql`
  query PhotosPageQuery {
    allContentfulPhotos(limit: 1000) {
      edges {
        node {
          id
          title
          slug
          photos {
            fixed(width: 600, height: 600, quality: 100) {
              src
            }
            file{
              url
            }
            title
            id
          }
        }
      }
    }
    contentfulPageHeader(title: {eq: "photos"}) {
      heroImage {
        fixed(width: 2000, height: 500) {
          src
        }
        title
      }
      title
      body {
        body
      }
    } 
  }
`;
